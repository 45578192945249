import type { RunningPromises } from '~/types/store'
import type { PagedResult } from '~/types/api'

export default function useCache() {
  const { apiAlert } = useAlert()

  async function loadList<ItemType, ResponseType extends PagedResult>(
    fetchedItems: Ref<ItemType[]>,
    promise: Ref<Promise<ItemType[]> | null>,
    loadingStatus: Ref<boolean>,
    apiCall: () => Promise<Ref<ResponseType>>
  ) {
    if (fetchedItems.value.length === 0) {
      if (promise.value !== null) {
        return promise.value
      }

      loadingStatus.value = true

      promise.value = apiCall()
        .then((result) => {
          fetchedItems.value = result?.items ?? []
          loadingStatus.value = false
        })
        .catch(apiAlert)
      return promise.value
    }

    return Promise.resolve(fetchedItems.value)
  }

  /**
   *
   * @param ids - requested items ids
   * @param fetchedItems - cache object
   * @param runningPromises - running promises object
   * @param loadItemsFnc - function returning promise for loading items to cache
   * @type CacheType - Cache object type
   * @type ItemType - Cache item type
   */
  async function getAllPromises<CacheType, ItemType>(
    ids: number[],
    fetchedItems: Ref<CacheType>,
    runningPromises: Ref<RunningPromises>,
    loadItemsFnc: (arg: number[]) => Promise
  ) {
    const missedIds = [
      ...new Set(
        ids.filter(
          (id: number) =>
            fetchedItems.value[id] === undefined && runningPromises.value[id] === undefined
        )
      ),
    ]
    const promises: Promise<ItemType>[] = ids
      .filter((id: number) => runningPromises.value[id])
      .map((id: number) => runningPromises.value[id])

    // load items in chunks due to paging - 25 items * 4 translations = 100
    const chunkSize = 25
    for (let i = 0; i < missedIds.length; i += chunkSize) {
      const chunk = missedIds.slice(i, i + chunkSize)
      promises.push(loadItemsFnc(chunk))
    }
    return await Promise.allSettled(promises)
  }

  /**
   *
   * @param ids
   * @param runningPromises
   * @param promise
   * @type ItemSet - Type of single item in cache
   */
  function loadRunningPromises<ItemSet>(
    ids: number[],
    runningPromises: RunningPromises<ItemSet>,
    promise: Promise
  ) {
    ids.forEach(
      (id: number) =>
        (runningPromises[id] = promise.finally(
          () => delete runningPromises[id]
        ))
    )
  }

  return {
    loadList,
    getAllPromises,
    loadRunningPromises,
  }
}
